import { supabase } from './supabase';

export const updateLessonProgress = async ({ courseId, lessonId, stepIndex, progress }) => {
  try {
    const { data, error } = await supabase.functions.invoke('admin-operations', {
      body: {
        action: 'updateLessonProgress',
        courseId,
        lessonId,
        stepIndex,
        progress
      }
    });

    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error updating lesson progress:', error);
    return { data: null, error };
  }
};

export const updateInstructionProgress = async ({ courseId, lessonId, stepIndex, progress }) => {
  try {
    const { data, error } = await supabase.functions.invoke('admin-operations', {
      body: {
        action: 'updateInstructionProgress',
        courseId,
        lessonId,
        stepIndex,
        progress
      }
    });

    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error updating instruction progress:', error);
    return { data: null, error };
  }
};

export const updateInputProgress = async ({ courseId, lessonId, stepIndex, progress }) => {
  try {
    const { data, error } = await supabase.functions.invoke('admin-operations', {
      body: {
        action: 'updateInputProgress',
        courseId,
        lessonId,
        stepIndex,
        progress
      }
    });

    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error updating input progress:', error);
    return { data: null, error };
  }
};
